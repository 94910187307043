import { useLocation } from "@reach/router"
import PathEnJson from "./data/en-pathname.json"
import PathIdJson from "./data/id-pathname.json"
import MainPageEnJson from "./data/en-main-page.json"
import MainPageIdJson from "./data/id-main-page.json"
import HowItWorksEnJson from "./data/en-how-it-works.json"
import HowItWorksIdJson from "./data/id-how-it-works.json"
import CountriesEnJson from "./data/en-countries.json"
import CountriesIdJson from "./data/id-countries.json"
import BusinessEnJson from "./data/en-business.json"
import BusinessIdJson from "./data/id-business.json"
import NewBusinessEnJson from "./data/en-business-new.json"
import NewBusinessIdJson from "./data/id-business-new.json"
import ContactUsEnJson from "./data/en-contact-us.json"
import ContactUsIdJson from "./data/id-contact-us.json"
import AboutUsEnJson from "./data/en-about-us.json"
import AboutUsIdJson from "./data/id-about-us.json"
import FaqEnJson from "./data/en-faq.json"
import FaqIdJson from "./data/id-faq.json"
import ServiceEnJson from "./data/en-service.json"
import ServiceIdJson from "./data/id-service.json"
import PrivacyEnJson from "./data/en-privacy-policy.json"
import PrivacyIdJson from "./data/id-privacy-policy.json"
import AffiliateEnJson from "./data/en-affiliate.json"
import AffiliateIdJson from "./data/id-affiliate.json"
import SendMoneyEnJson from "./data/en-send-money.json"
import SendMoneyIdJson from "./data/id-send-money.json"
import DynamicCountries from "./data/dynamic-countries.json"
import Error404EnJson from "./data/en-404.json"
import Error404IdJson from "./data/id-404.json"
import CalculatorEnJson from "./data/en-calculator.json"
import CalculatorIdJson from "./data/id-calculator.json"
import AffiliateFormEnJson from "./data/en-affiliate-form.json"
import AffiliateFormIdJson from "./data/id-affiliate-form.json"
import SwiftCodesEnJson from "./data/en-swift-codes.json"
import SwiftCodesIdJson from "./data/id-swift-codes.json"
import SyaratReferralEnJson from "./data/en-syarat-referral.json"
import SyaratReferralIdJson from "./data/id-syarat-referral.json"
import CookiePolicyEnJson from "./data/en-cookie-policy.json"
import CookiePolicyIdJson from "./data/id-cookie-policy.json"
import ForgotPasswordEnJson from "./data/en-forgot-password.json"
import ForgotPasswordIdJson from "./data/id-forgot-password.json"
import HowToSgEnJson from "./data/en-how-to-sg.json"
import HowToSgIdJson from "./data/id-how-to-sg.json"
import HowToIdEnJson from "./data/en-how-to-id.json"
import HowToIdIdJson from "./data/id-how-to-id.json"
import SingaporeServiceAgreementEnJson from "./data/en-singapore-service-agreement.json"
import SingaporePrivacyPolicyEnJson from "./data/en-singapore-privacy-policy.json"
import SingaporePrivacyPolicyIdJson from "./data/id-singapore-privacy-policy.json"
import SingaporeFaqEnJson from "./data/en-singapore-faq.json"
import SingaporeFaqIdJson from "./data/id-singapore-faq.json"
import PrivacyPolicyNewEnJson from "./data/en-new-privacy-policy.json"
import PrivacyPolicyNewIdJson from "./data/id-new-privacy-policy.json"
import SingaporeReferralEnJson from "./data/en-sg-referral.json"
import SingaporeReferralIdJson from "./data/id-sg-referral.json"
import SingaporeReferralTncEnJson from "./data/en-sg-referral-tnc.json"
import SingaporeReferralTncIdJson from "./data/id-sg-referral-tnc.json"
import ReferralEnJson from './data/en-referral.json'
import ReferralIdJson from './data/id-referral.json'
import AdvanceFAQEnJson from './data/en-advance-faq.json'
import AdvanceFAQIdJson from './data/id-advance-faq.json'
import EwalletTncEnJson from './data/en-ewallet-tnc.json'
import EwalletTncIdJson from './data/id-ewallet-tnc.json'
import BusinessMobileTncEnJson from './data/en-business-mobile-tnc.json'
import BusinessMobileTncIdJson from './data/id-business-mobile-tnc.json'

export function MainPageJson() {
  const location = useLocation()
  return location.pathname.includes("/en")
    ? MainPageEnJson.MainPage
    : MainPageIdJson.MainPage
}

export function PathJson() {
  const location = useLocation()
  return location.pathname.includes("/en")
    ? PathEnJson.Pathname
    : PathIdJson.Pathname
}

export function HowItWorksJson() {
  const location = useLocation()
  return location.pathname.includes("/en")
    ? HowItWorksEnJson.HowItWorks
    : HowItWorksIdJson.HowItWorks
}

export function CountriesJson() {
  const location = useLocation()
  return location.pathname.includes("/en")
    ? CountriesEnJson.Countries
    : CountriesIdJson.Countries
}

export function BusinessJson() {
  const location = useLocation()
  return location.pathname.includes("/en")
    ? BusinessEnJson.Business
    : BusinessIdJson.Business
}

export function ContactUsJson() {
  const location = useLocation()
  return location.pathname.includes("/en")
    ? ContactUsEnJson.ContactUs
    : ContactUsIdJson.ContactUs
}

export function AboutUsJson() {
  const location = useLocation()
  return location.pathname.includes("/en")
    ? AboutUsEnJson.AboutUs
    : AboutUsIdJson.AboutUs
}

export function FaqJson() {
  const location = useLocation()
  return location.pathname.includes("/en") ? FaqEnJson.Faq : FaqIdJson.Faq
}

export function ServiceJson() {
  const location = useLocation()
  return location.pathname.includes("/en")
    ? ServiceEnJson.Service
    : ServiceIdJson.Service
}

export function PrivacyJson() {
  const location = useLocation()
  return location.pathname.includes("/en")
    ? PrivacyPolicyNewEnJson.Privacy
    : PrivacyPolicyNewIdJson.Privacy
}

// SEMENTARA PRIVACY INDO BAHASA PAKE YG LAMA

export function PrivacyOldJson() {
  const location = useLocation()
  return location.pathname.includes("/en")
    ? PrivacyIdJson.Privacy
    : PrivacyIdJson.Privacy
}

// ==========================

export function AffiliateJson() {
  const location = useLocation()
  return location.pathname.includes("/en")
    ? AffiliateEnJson.Affiliate
    : AffiliateIdJson.Affiliate
}

export function SendMoneyJson() {
  const location = useLocation()
  return location.pathname.includes("/en")
    ? SendMoneyEnJson.SendMoney
    : SendMoneyIdJson.SendMoney
}

export function SendMoneyPath() {
  const location = useLocation()
  return location.pathname.includes("/send-money")
}

export function CountryListJson() {
  const location = useLocation()
  // SORT ASCENDING
  return location.pathname.includes("/en")
    ? DynamicCountries.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      )
    : DynamicCountries.sort((a, b) =>
        a.nama > b.nama ? 1 : b.nama > a.nama ? -1 : 0
      )
}

export function Error404Json() {
  const location = useLocation()
  return location.pathname.includes("/en")
    ? Error404EnJson.Error
    : Error404IdJson.Error
}

export function CalculatorJson() {
  const location = useLocation()
  return location.pathname.includes("/en")
    ? CalculatorEnJson.Calculator
    : CalculatorIdJson.Calculator
}

export function AffiliateFormJson() {
  const location = useLocation()
  return location.pathname.includes("/en")
    ? AffiliateFormEnJson.AffiliateForm
    : AffiliateFormIdJson.AffiliateForm
}

export function SwiftCodesJson() {
  const location = useLocation()
  return location.pathname.includes("/en")
    ? SwiftCodesEnJson.SwiftCodes
    : SwiftCodesIdJson.SwiftCodes
}

export function SyaratReferralJson() {
  const location = useLocation()
  return location.pathname.includes("/en")
    ? SyaratReferralEnJson.Syarat
    : SyaratReferralIdJson.Syarat
}

export function CookiePolicyJson() {
  const location = useLocation()
  return location.pathname.includes("/en")
    ? CookiePolicyEnJson.Cookie
    : CookiePolicyIdJson.Cookie
}

export function ForgotPasswordJson() {
  const location = useLocation()
  return location.pathname.includes("/en")
    ? ForgotPasswordEnJson.ForgotPassword
    : ForgotPasswordIdJson.ForgotPassword
}

export function SingaporeServiceAgreementJson() {
  const location = useLocation()
  return location.pathname.includes("/en")
    ? SingaporeServiceAgreementEnJson.Service
    : SingaporeServiceAgreementEnJson.Service
}

export function HowToSgJson() {
  const location = useLocation()
  return location.pathname.includes("/en")
    ? HowToSgEnJson.HowTo
    : HowToSgIdJson.HowTo
}
export function HowToIdJson() {
  const location = useLocation()
  return location.pathname.includes("/en")
    ? HowToIdEnJson.HowTo
    : HowToIdIdJson.HowTo
}

export function SingaporePrivacyPolicyJson() {
  const location = useLocation()
  return location.pathname.includes("/en")
    ? SingaporePrivacyPolicyEnJson.Privacy
    : SingaporePrivacyPolicyIdJson.Privacy
}

export function SingaporeFaqJson() {
  const location = useLocation()
  return location.pathname.includes("/en")
    ? SingaporeFaqEnJson.FAQ
    : SingaporeFaqIdJson.FAQ
}

export function BusinessNewJson() {
  const location = useLocation()
  return location.pathname.includes("/en")
    ? NewBusinessEnJson.Business
    : NewBusinessIdJson.Business
}

export function SgReferralJson() {
  const location = useLocation()
  return location.pathname.includes("/en")
    ? SingaporeReferralEnJson.Referral
    : SingaporeReferralIdJson.Referral
}

export function SgReferralTncJson() {
  const location = useLocation()
  return location.pathname.includes("/en")
    ? SingaporeReferralTncEnJson.Tnc
    : SingaporeReferralTncIdJson.Tnc
}

export function ReferralJson() {
  const location = useLocation()
  return location.pathname.includes("/en")
    ? ReferralEnJson.Referral
    : ReferralIdJson.Referral
}

export function AdvanceFAQJson() {
  const location = useLocation()
  return location.pathname.includes("/en")
    ? AdvanceFAQEnJson.AdvanceFAQ
    : AdvanceFAQIdJson.AdvanceFAQ
}

export function EwalletTncJson() {
  const location = useLocation()
  return location.pathname.includes("/en")
    ? EwalletTncEnJson.Tnc
    : EwalletTncIdJson.Tnc
}
export function BusinessMobileTncJson() {
  const location = useLocation()
  return location.pathname.includes("/en")
    ? BusinessMobileTncEnJson.Tnc
    : BusinessMobileTncIdJson.Tnc
}